<template>
  <div class="onboarding-swiper-wrapper">
    <h2 class="onboarding-content-title">Crave or hard pass?</h2>
    <p class="text-center">Swipe left if it's a no.</p>

    <OnboardingSwiper
      :list="list"
      @finish="$router.push('/onboarding/places')"
    />
  </div>
</template>

<script>
import OnboardingSwiper from '../../components-rf/onboarding/OnboardingSwiper.vue'

const list = [
  {
    id: 1,
    src: require('@/assets/onboarding/food/sushi.png'),
    text: 'Rolls on rolls on rolls.',
  },
  {
    id: 2,
    src: require('@/assets/onboarding/food/charcuterie.png'),
    text: 'Charcuterie as faaar as the eye can see.',
  },
  {
    id: 3,
    src: require('@/assets/onboarding/food/fruits.png'),
    text: 'Exotic, fire-breathing fruits forever.',
  },
  {
    id: 4,
    src: require('@/assets/onboarding/food/dim-sum.png'),
    text: 'Dim sum with some serious sauces.',
  },
  {
    id: 5,
    src: require('@/assets/onboarding/food/protein.png'),
    text: 'Crunchy. Crickety. Protein.',
  },
]

export default {
  name: 'OnboardingFood',
  components: { OnboardingSwiper },
  data() {
    return { list }
  },
}
</script>

<style lang="scss" scoped></style>
