<template>
  <div class="onboarding-swiper">
    <TransitionGroup class="onboarding-swiper-image-wrapper" name="fade">
      <img
        v-for="item in itemList"
        :key="item.id"
        :src="item.src"
        :alt="item.text"
        :data-active="activeItem.id === item.id"
        :data-dragged="isDragged"
        :draggable="activeItem.id === item.id"
        class="onboarding-swiper-image"
        @dragstart="onDragStart"
        @drag="onDrag"
      />
    </TransitionGroup>
    <p style="min-height: 50px">
      {{ activeItem.text }}
    </p>
    <div class="onboarding-swiper-buttons">
      <button @click="next(false)">
        <IconSwipeDislike />
      </button>
      <button @click="next(true)">
        <IconSwipeLike />
      </button>
    </div>
  </div>
</template>

<script>
import IconSwipeDislike from '../icons/onboarding/IconSwipeDislike.vue'
import IconSwipeLike from '../icons/onboarding/IconSwipeLike.vue'
const dragLog = []
const dragThreshold = 150

export default {
  name: 'OnboardingSwiper',
  components: { IconSwipeDislike, IconSwipeLike },
  props: { list: { type: Array, required: true } },
  created() {
    this.itemList = [...this.list].reverse()
  },
  data() {
    return {
      itemList: [],
      isDragged: undefined,
    }
  },
  methods: {
    next(like) {
      if (like) this.$emit('like', this.activeItem)
      else this.$emit('dislike', this.activeItem)

      if (this.itemList.length === 1) this.$emit('finish')
      else this.itemList.pop()
    },
    onDragStart(e) {
      const img = e.target.cloneNode(true)
      const div = document.createElement('div')
      div.classList.add('onboarding-swiper-image-ghost')
      div.appendChild(img)
      e.dataTransfer.setDragImage(div, 0, 0)
    },
    onDrag(e) {
      this.isDragged = true
      const x = e.clientX
      if (x !== 0) {
        dragLog.push(x)
        return
      }
      const weight = dragLog.at(-2) - dragLog.at(0)
      const liked = weight > 0
      const thresholdReached = Math.abs(weight) > dragThreshold

      if (thresholdReached) this.next(liked)
      this.isDragged = false
      dragLog.length = 0
    },
  },
  computed: {
    activeItem() {
      return this.itemList[this.itemList.length - 1]
    },
  },
}
</script>

<style scoped lang="scss">
.onboarding-swiper {
  display: grid;
  justify-items: center;
}
.onboarding-swiper-image-wrapper {
  position: relative;
  width: 270px;
  height: 270px;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.onboarding-swiper-image {
  --image-size: 260px;
  position: absolute;
  transition-property: width height bottom;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  cursor: pointer;
  &[data-active='true'] {
    width: var(--image-size);
    height: var(--image-size);
    bottom: 10px;
  }
  &:not([data-active='true']) {
    pointer-events: none;
    width: 220px;
    height: 220px;
    bottom: 0;
    filter: brightness(50%);
  }
  &[data-active='true'][data-dragged='true'] {
    opacity: 0;
  }
  &-ghost {
    width: var(--image-size);
    height: var(--image-size);
  }
}
.onboarding-swiper-buttons {
  margin-top: 1rem;
  display: flex;
  & > * + * {
    margin-left: 3rem;
  }
  & > button {
    border-radius: 100%;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #d2d2d2;
    background: #fff1;
    box-shadow: 0 0 1px #0002;
    &:hover {
      background: #fff2;
      box-shadow: 0 0 2px #0003;
    }
  }
}
</style>
